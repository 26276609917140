
h1 {
    font-family: 'Amatic SC', cursive;
}
.title {
    font-family: 'Amatic SC', cursive;
    font-size: 28px;
    margin-top: 0;
}

.horizontal-divider {
    width: 100%; /* Set the width of the divider to span the full container */
    height: 1px; /* Set the height of the divider */
    background-color: #FFFFFF; /* Set the color of the divider */
    margin-bottom: 40px;
}
