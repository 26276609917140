h1, h2, ion-title, ion-card-title, u{
    font-family: 'Amatic SC', cursive;
}

h1 {
    font-size: 42px;
}

ion-list-header {
    font-family: 'Amatic SC', cursive ;
    ion-text {
        font-family: 'Amatic SC', cursive;
    }
}

ion-title {
    ion-text {
        font-family: 'Amatic SC', cursive;
    }
    
} 

* {
    font-family: 'Lato', sans-serif ;
}

ion-card {
    border-radius: 24px;
}

ion-button {
    text-transform: none;
}


.story-text {
    font-size: 16px;
    line-height: 1.5;
    font-family: 'Lato', sans-serif !important;
}

ion-loading {
    .loading-wrapper {
        font-size: 18px;
        border-radius: 16px !important;
        border: solid 1px var(--ion-color-primary);
        line-height: 1.5;
    }
}

ion-item {
    --background: none;
}

ion-alert {
    .alert-wrapper {
        border-radius: 16px !important;
        border: solid 1px var(--ion-color-primary);
        font-size: 20px;
        .alert-message {
            font-size: 20px;
        }
        .alert-button-group{
            .alert-button{
                font-size: 20px;
            }
        }
    }
}

ion-select-popover {
    overflow: auto;
}

.custom-shape-divider-top-1705705511 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1705705511 svg {
    position: relative;
    display: block;
    width: calc(183% + 1.3px);
    height: 252px;
}

.custom-shape-divider-top-1705705511 .shape-fill {
    /* fill: #FBE8BF; */
    fill: var(--ion-color-shape-divider)
}

ion-input {
    --border-color: var(--ion-color-primary) !important;
}

ion-select {
    --border-color: var(--ion-color-primary) !important;
}

ion-textarea {
    --border-color: var(--ion-color-primary) !important;
}

.top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
    margin-bottom: 100px;
    position: relative;
    text-align: center;
    p {
        font-size: 14px;
        margin: 0;
    }
    h1 {
        font-size: 42px;
    }
}

ion-toast.copy-toast {
    --background: var(--ion-color-primary);
    --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
    --border-radius: 24px;
    --width: 250px;
    text-align: center;
    /* --color: #4b4a50; */
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 40px;
  }
  
  .separator::before,
  .separator::after {
    content: '';
    flex: 1;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.2); */
    border-bottom: 1px solid var(--ion-color-step-500, rgba(0, 0, 0, 0.2));
  }
  
  .separator:not(:empty)::before {
    margin-right: 1em;
  }
  
  .separator:not(:empty)::after {
    margin-left: 1em;
  }

  .overlay {
    z-index: 9999; 
  }
  