.auto-resize-textarea {
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide overflow */
    min-height: 40px; /* Set minimum height */
    height: auto; /* Set height to auto */
  }

  ion-row:not(.footer):not(.stories) {
    border-radius: 16px !important;
    background: var(--ion-row-background-color);
    --background: transparent;
  }

  h3 {
    margin: 0;
  }
  
  .item-create {
    border-radius: 28px !important;
    border: solid 2px var(--ion-color-primary);
  }

  ion-list {
    background: transparent !important;
  }

  ion-label {
    font-family: 'Lato', sans-serif !important;
  }

  .example-setting {
    /* color: var(--ion-color-step-550, #737373) */
    color: var(--ion-color-primary);
    margin-top: 0;
  }

  .story-count {
    margin-right: 4px;
    margin-left: 3px;
    padding-left: 3px;
    padding-right: 4px;
    background-color: var(--ion-color-primary);
    border-radius: 8px;
    padding-bottom: 1px;
  }