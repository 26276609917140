.avatar-container {
    background: var(--ion-color-primary);
    width: 42px;
    height: 42px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 10px; */
    margin-right: 10px;
}


.toggle-container {
    border: 1px solid var(--ion-color-primary);
    width: 56px;
    height: 56px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-left: 10px;
}

.story-count-2 {
    margin-right: 4px;
    /* margin-left: 2px; */
    padding-left: 5px;
    padding-right: 3px;
    background-color: var(--ion-color-primary);
    border-radius: 8px;
    padding-bottom: 1px;
  }
