.story-image {
    width: 100%;
    max-width: 140px;
    height: auto;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.6);

}

.summary {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

ion-card {
    --background: var(--ion-row-background-color);
    h2 {
        color: var(--ion-text-color);
    }
}
