.footer-grid {
    color: #555555;
    ion-icon {
        cursor: pointer;
    }
}

.icon-container {
    /* display: flex; */
    align-items: center;
    padding: 0;
    a {
        color: #555555 !important
    }
    
}

@media (min-width: 768px) {
    .icon-container {
        display: flex;
        justify-content: flex-end;
    }
}